import classNames from 'classnames/bind';
import { graphql, Link, PageProps } from 'gatsby';
import React from 'react';
import { Intro } from '../components/Intro';
import { Layout } from '../components/Layout/index';
import SEO from '../components/SEO/SEO';
import * as styles from './blog.module.scss';

const cx = classNames.bind(styles);

const BlogPage: React.FC<PageProps<any>> = ({ data }) => (
  <Layout>
    <SEO title="Email Marketing Blog" />

    <Intro>
      <h2>Blog</h2>
      <h3>Tutorials, getting started guides and how-to's</h3>
    </Intro>

    <div className="container">
      <div className="content">
        <div className="grid">
          {data.allMarkdownRemark.edges.map(
            ({
              node: {
                excerpt,
                frontmatter: { date, formattedDate, slug, title },
              },
            }: any) => (
              <div className="col-4_md-6_sm-12" key={slug}>
                <div className={cx('article')}>
                  <Link to={`/blog/${slug}/`}>
                    <h3 style={{ lineHeight: 1.2 }}>{title}</h3>
                    <p style={{ color: '#666' }}>{excerpt}</p>
                  </Link>
                  {/* <div className={cx('read-more-wrapper')}>
                    <Link to={`/blog/${slug}/`}>Read more</Link>
                  </div> */}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/.*\\.md$/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            date
            formattedDate: date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

export default BlogPage;
